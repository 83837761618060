import { lazy } from 'react';
import { Loadable } from '../../Loadable';

export const NotebookList = Loadable(lazy(() => import('src/pages/notebooks/NotebookList')));
export const NotebookCreate = Loadable(lazy(() => import('src/pages/notebooks/NotebookCreate')));
export const NotebookListQuestions = Loadable(
  lazy(() => import('src/pages/notebooks/NotebookListQuestion'))
);
export const NotebookListQuestionsAnswer = Loadable(
  lazy(() => import('src/pages/notebooks/NotebookListQuestionAnswer'))
);
