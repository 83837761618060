// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps } from '@mui/material';

// ----------------------------------------------------------------------

type IProps = {
  name: string
  onInputChange?: (value: string) => void
} & TextFieldProps

export default function RHFTextField({ name, onInputChange, ...other }: IProps & TextFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          fullWidth
          error={!!error}
          helperText={error?.message}
          value={value}
          onChange={({ target }) => {
            onInputChange && onInputChange(target.value)
            onChange(target.value)
          }}
          {...other}
        />
      )}
    />
  );
}
