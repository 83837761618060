// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};


export const PATH_PAGE = {
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
};

export const PATH_USERS = {
  cms: {
    root: '/manager',
    list: '/manager/list',
    newUser: '/manager/new',
    editById: `/manager/:id/edit`,
  },
  app: {
    root: '/customer',
    list: '/customer/list',
    editById: `/customer/:id/edit`,
  },
};

export const PATH_QUESTIONS = {
  default: {
    root: '/questions',
    list: '/questions/list',
    newQuestion: '/questions/new',
    editById: `/questions/:id/edit`,
  },
  user: {
    newQuestion: '/questions-users/new',
    root: '/questions-users',
    list: '/questions-users/list',
    editById: `/questions-users/:id/edit`,
  },
};

export const PATH_NOTEBOOK = {
  root: '/notebooks',
  list: '/notebooks/list',
  editById: `/questions/users/:id/edit`,
  listQuestions: '/notebooks/:id/questions/:title',
};

export const PATH_COVER = {
  root: '/cover',
};

export const PATH_ANSWERS = {
  root: '/answer',
  list: '/answer/list',
  editById: `/answer/:id/edit`,
};

export const PATH_CATEGORY_ICONS = {
  root: '/icons',
  list: '/icons/list',
  editById: `/icons/:id/edit`,
  iconByCategory: `/icons/new/:id/category`,
  iconEditByCategory: `/icons/edit/:id/category/:idIcon`,
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';

export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app;
