import jwtDecode from 'jwt-decode';
import { verify, sign } from 'jsonwebtoken';
// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null, user?: any) => {
  if (accessToken && user) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('loggedUser', JSON.stringify(user));
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('loggedUser');
  }
};

export { isValidToken, setSession, verify, sign };
