import { Navigate } from 'react-router-dom';

import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout';
import PageTerms from 'src/pages/PageTerms';

import { Maintenance, NotFound, Page500 } from '../../components/pageComponents/Main'

export const routes = [
  {
    path: '*',
    element: <LogoOnlyLayout />,
    children: [
      { path: 'maintenance', element: <Maintenance /> },
      { path: '404', element: <NotFound /> },
      { path: '500', element: <Page500 /> },
      { path: '*', element: <Navigate to="/404" replace /> },
      { path: 'Terms', element: <PageTerms /> },
    ],
  },
  {
    path: '/',
    element: <LogoOnlyLayout />,
    children: [{ element: <Navigate to="/auth/login" replace />, index: true }],
  },
  { path: '*', element: <Navigate to="/404" replace /> },
]