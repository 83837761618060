import { useLayoutEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// hooks

import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useAuth from 'src/hooks/useAuth';
// config
import { HEADER, NAVBAR } from '../../config';
import { PATH_AUTH } from 'src/routes/paths';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import { serverApi } from 'src/api';

// ----------------------------------------------------------------------

type MainStyleProps = {
  collapseClick: boolean;
};

type DashboardLayoutProps = {
  accessibleRoles?: string[];
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout({ accessibleRoles }: DashboardLayoutProps) {
  const navigate = useNavigate();
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const { logout } = useAuth();
  const accessToken = localStorage.getItem('accessToken');

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleLogout = async () => {
    setLoading(true);
    await logout();
    navigate(PATH_AUTH.login);
  };

  useLayoutEffect(() => {
    async function getToken() {
      const response = await serverApi.auth.loginValid();
      if (!accessToken || !response.data.valid) {
        handleLogout();
      }
    }
    getToken();
  }, [accessToken]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        {!!accessibleRoles && <RoleBasedGuard accessibleRoles={accessibleRoles} />}

        {!accessibleRoles && <Outlet />}
      </MainStyle>
    </Box>
  );
}
