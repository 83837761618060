import { BrowserRouter as Router } from 'react-router-dom';

import AppProvider from 'src/contexts';

import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import NotistackProvider from './components/NotistackProvider';

import Routes from './routes';

import './theme/Iframe.css';

export default function App() {
  return (
    <Router>
      <AppProvider>
        <RtlLayout>
          <NotistackProvider>
            <MotionLazyContainer>
              <ProgressBarStyle />
              <ScrollToTop />
              <Routes />
            </MotionLazyContainer>
          </NotistackProvider>
        </RtlLayout>
      </AppProvider>
    </Router>
  );
}
