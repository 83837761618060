import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { List, Box, ListSubheader, Grid, Avatar, Typography, Link } from '@mui/material';
// type
import { NavSectionProps } from '../type';
//
import { NavListRoot } from './NavList';
import { PATH_AUTH, PATH_USERS } from 'src/routes/paths';
import { paramCase } from 'change-case';
import { ListItemIconStyle, ListItemStyle, ListItemTextStyle } from './style';
import { getActive } from '..';
import useAuth from 'src/hooks/useAuth';
import getIcon from 'src/utils/svgs';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
  fontWeight: 'bold',
}));

// ----------------------------------------------------------------------

export default function NavSectionVertical({
  navConfig,
  isCollapse = false,
  user,
  ...other
}: NavSectionProps) {
  const theme = useTheme();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const { logout } = useAuth();

  const handleLogout = async () => {
    await logout();
    navigate(PATH_AUTH.login);
  };

  const stringAvatar = (name: string) => ({
    sx: {
      bgcolor: theme.palette.primary.main,
      color: '#fff',
    },
    children: `${
      name?.split(' ').length > 1
        ? name.split(' ')[0][0] + name?.split(' ')[1][0]
        : name.split(' ')[0][0]
    }`,
  });

  const active = getActive('/dashboard/app', pathname);

  return (
    <Box {...other}>
      {!!user && !!user._id && (
        <Box>
          <Box
            sx={{
              padding: 3,
              pl: isCollapse ? 3 : 5,
            }}
          >
            <Grid
              sx={{
                backgroundColor: !isCollapse ? theme.palette.grey[200] : 'none',
                pb: 2,
                borderRadius: 2,
                textDecoration: 'none',
                color: theme.palette.text.primary,
              }}
              container
              spacing={2}
              component={RouterLink}
              to={`${PATH_USERS.cms.root}/${paramCase(user?._id ?? '')}/edit`}
            >
              <Grid item>
                <Avatar
                  {...stringAvatar(user?.name ?? '')}
                  src={user?.profilePhoto}
                  alt={user?.name}
                />
              </Grid>
              {!isCollapse && (
                <Grid item>
                  <Typography fontSize={15} variant="subtitle1">
                    {user?.name}
                  </Typography>
                  <Typography fontSize={15} variant="body1">
                    {''}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
          <Box sx={{ p: 2 }}>
            <ListItemStyle component={Link} href="/dashboard/app" activeRoot={active}>
              <ListItemIconStyle>{getIcon('ic_charts')}</ListItemIconStyle>
              {!isCollapse && <ListItemTextStyle disableTypography primary={'Dashboard'} />}
            </ListItemStyle>
          </Box>
          {navConfig.map((group) => (
            <List key={group.subheader} disablePadding sx={{ px: 2 }}>
              <ListSubheaderStyle
                sx={{
                  ...(isCollapse && {
                    opacity: 0,
                  }),
                }}
              >
                {group.subheader}
              </ListSubheaderStyle>

              {group.items.map((list) => (
                <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
              ))}
            </List>
          ))}
          <Box sx={{ p: 2 }}>
            <ListItemStyle onClick={handleLogout}>
              <ListItemIconStyle sx={{ color: theme.palette.error.main }}>
                {getIcon('ic_close')}
              </ListItemIconStyle>
              <Typography sx={{ color: theme.palette.error.main }}>Logout</Typography>
            </ListItemStyle>
          </Box>
        </Box>
      )}
    </Box>
  );
}
