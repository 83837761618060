import axiosInstance from './config';
import { UserManager } from 'src/@types/user';
import { NotificationCreate } from 'src/@types/notification';
import { QuestionsDefault } from 'src/@types/questions';

const serverApi = {
  axios: axiosInstance,

  auth: {
    login: (email: string, password: string) =>
      axiosInstance.post('/sessions', { email, password }),
    loginValid: () =>
      axiosInstance.get('/user/is-valid-token'),
    register: (data: any) => axiosInstance.post('/auth/register', data),
    resetPassword: (data: any) => axiosInstance.post('/auth/reset-password', data),
    verify: (data: any) => axiosInstance.post('/auth/verify', data),
    loginUnprotected: (data: any) => axiosInstance.post('/auth/login-unprotected', data),
    registerUnprotected: (data: any) => axiosInstance.post('/auth/register-unprotected', data),
    
  },

  notifications: {
    list: () => axiosInstance.get('/notifications'),
    create: (data: NotificationCreate) => axiosInstance.post('/notifications/create', data),
  },

  cover: {
    list: () => axiosInstance.get('/cover'),
    create: (data: any) => axiosInstance.post('/cover', data),
    delete: (id: string) => axiosInstance.delete(`/cover/${id}`),
    getById: (id: string) => axiosInstance.get(`/cover/${id}`),
    update: (id: string, data: any) => axiosInstance.put(`/cover/${id}`, data),
  },
  iconsCategory: {
    list: () => axiosInstance.get('/category-icon'),
    create: (data: any) => axiosInstance.post('/category-icon', data),
    delete: (id: string) => axiosInstance.delete(`/category-icon/${id}`),
    getById: (id: string) => axiosInstance.get(`/category-icon/${id}`),
    update: (id: string, data: any) => axiosInstance.put(`/category-icon/${id}`, data),
  },
  icons: {
    list: () => axiosInstance.get('/icon'),
    create: (data: any) => axiosInstance.post('/icon', data),
    update: (id: string, data: any) => axiosInstance.put(`/icon/${id}`, data),
    delete: (id: string) => axiosInstance.delete(`/icon/${id}`),
    getById: (id: string) => axiosInstance.get(`/icon/${id}`),
  },
  user: {
    customer: {
      listAll: (skip: number, limit: number, filterRadio: string, filterName: string) =>  {
        if (filterRadio && filterName) {
          return axiosInstance.get(`/users?skip=${skip}&limit=${limit}&${filterRadio}=${filterName}`)
        } 
        return axiosInstance.get(`/users?skip=${skip}&limit=${limit}`)
      },
      getAll: (value: string, tag = 'name') =>
        axiosInstance.get(`/users?${tag}=${value}`),
      getById: (id: string) => axiosInstance.get(`/users/${id}`),
      update: (id: string, data: any) => axiosInstance.put(`/users/${id}`, data),
      delete: (id: string) => axiosInstance.delete(`/users/${id}`),
    },

    manager: {
      listAllAdmin: (skip: number, limit: number, filterRadio: string, filterName: string) =>  {
        if (filterRadio && filterName) {
          return axiosInstance.get(`/users-admin?skip=${skip}&limit=${limit}&${filterRadio}=${filterName}`)
        } 
        return axiosInstance.get(`/users-admin?skip=${skip}&limit=${limit}`)
      },
      loggedUser: () => axiosInstance.get('/user/me'),
      getAll: (filterName: string, skip: number, limit: number) =>
        axiosInstance.get(`/users?name=${filterName}&skip=${skip}&limit=${limit}`),
      getById: (id: string) => axiosInstance.get(`/users/${id}`),
      create: (data: UserManager) => axiosInstance.post('/users/store-admin', data),
      update: (id: string, data: any) => axiosInstance.put(`/users/${id}`, data),
      delete: (id: string) => axiosInstance.delete(`/users/${id}`),
      changePassword: (id: string, newPassword: string) =>
        axiosInstance.put(`/users/${id}/change-pass`, { newPassword }),
    },
  },
  questionsNotebook: {
    delete: (id: string) => axiosInstance.delete(`/notebook-question/${id}`),
  },
  questions : {
    answersQuestionDelete: (id: string) => axiosInstance.delete(`/answer-question/${id}`),
    required: {
      listRequired: (skip: number, limit: number) => axiosInstance.get(`/question-by-required?skip=${skip}&limit=${limit}`),
    },
    users: {
      listAll: (skip: number, limit: number, filterRadio: string, filterName: string) =>  {
        if (filterRadio && filterName) {
          return axiosInstance.get(`/question-by-unrequired?skip=${skip}&limit=${limit}&${filterRadio}=${filterName}`)
        } 
        return axiosInstance.get(`/question-by-unrequired?skip=${skip}&limit=${limit}`)
      }
        
    },
    findByNotebook: (id: string, skip: number, limit: number, filterName: string) => {
      if (filterName) {
        return axiosInstance.get(`/question-by-notebook?notebook=${id}&skip=${skip}&limit=${limit}&title=${filterName}`)
      } 
      return axiosInstance.get(`/question-by-notebook?notebook=${id}&skip=${skip}&limit=${limit}`)
    },
    status: (id: string) => axiosInstance.patch(`/question/status-change/${id}`),
    answers: {
      findByQuestion: (id: string, skip: number, limit: number, filterRadio?: string, filterName?: string) => {
        if (filterName) {
          return axiosInstance.get(`/answer-by-question?question=${id}&skip=${skip}&limit=${limit}&${filterRadio}=${filterName}`)
        } 
        return axiosInstance.get(`/answer-by-question?question=${id}&skip=${skip}&limit=${limit}`)
      },
      delete: (id: string) => axiosInstance.delete(`/answer/${id}`),
      getById: (id: string) => axiosInstance.get(`/answer/${id}`),
      update: (id: string, data: any) => axiosInstance.put(`/answer/${id}`, data),
    },
    getById: (id: string) => axiosInstance.get(`/question/${id}`),
    update: (id: string, data: any) => axiosInstance.put(`/question/${id}`, data),
    create: (data: QuestionsDefault) => axiosInstance.post('/question', data),
    delete: (id: string) => axiosInstance.delete(`/question/${id}`),
  },

  notebook: {
    listAll: (skip: number, limit: number, privates: boolean, filterRadio: string, filterName: string) =>  {
      if (filterRadio && filterName) {
        return axiosInstance.get(`/notebook?skip=${skip}&limit=${limit}&privates=${privates}&${filterRadio}=${filterName}`)
      } 
      if (privates) return axiosInstance.get(`/notebook?skip=${skip}&limit=${limit}&privates=${privates}`)
      return axiosInstance.get(`/notebook?skip=${skip}&limit=${limit}`)
    },
    delete: (id: string) => axiosInstance.delete(`/notebook/${id}`),
    status: (id: string) => axiosInstance.patch(`/notebook/status-change/${id}`),
    getById: (id: string) => axiosInstance.get(`/notebook/${id}`),
    update: (id: string, data: any) => axiosInstance.put(`/notebook/${id}`, data),
  },

  general: {
    contactUs: {
      getTickets: (skip: number, limit: number, fullname: string) =>
        axiosInstance.get(`/contactUs?skip=${skip}&limit=${limit}&fullname=${fullname}`),
      updateTicket: (id: string) => axiosInstance.put(`/contactUs/${id}/solve`),
    },
  },
};

export { serverApi };
