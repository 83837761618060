// components
import { NavConfigProps } from 'src/components/nav-section/type';
import getIcon from 'src/utils/svgs';

// ----------------------------------------------------------------------

const ICONS = {
  user: getIcon('ic_user'),
  dashboard: getIcon('ic_dashboard'),
  cart: getIcon('ic_cart'),
  star: getIcon('ic_star'),
  interest: getIcon('ic_favorite'),
  post: getIcon('ic_elements'),
  events: getIcon('ic_booking'),
  calendar: getIcon('ic_booking'),
  library: getIcon('ic_add_photo_alternate'),
  article: getIcon('ic_editor'),
  notification: getIcon('ic_notifications'),
};

const navConfig = () => {
  const config = Array<NavConfigProps>();

  config.push({
    ...myCaderninhoConfig,
    items: myCaderninhoConfig.items,
  });
  return config;
};

const myCaderninhoConfig = {
  subheader: '',

  items: [
    {
      title: 'Usuários',
      icon: ICONS.user,
      path: '/manager',
      acessibleRoles: ['user', 'admin', 'sac'],
      children: [
        { title: 'Administrativo', path: '/manager/list', isOnlyAdmin: false },
        { title: 'Clientes', path: '/customer/list', isOnlyAdmin: false },
      ],
    },
    {
      title: 'Caderninhos',
      icon: ICONS.post,
      path: '/notebooks',
      acessibleRoles: ['user', 'admin', 'sac'],
    },
    {
      title: 'Questões',
      icon: ICONS.article,
      path: '/questions',
      acessibleRoles: ['user', 'admin', 'sac'],
      children: [
        { title: 'Obrigatórias', path: '/questions/list', isOnlyAdmin: false },
        { title: 'A escolher', path: '/questions-users/list', isOnlyAdmin: false },
      ],
    },
    {
      title: 'Imagem background',
      icon: ICONS.calendar,
      path: '/cover',
      acessibleRoles: ['user', 'admin', 'sac'],
    },
    {
      title: 'Ícones',
      icon: ICONS.calendar,
      path: '/icons',
      acessibleRoles: ['user', 'admin', 'sac'],
    },
    {
      title: 'Notificações',
      path: '/notifications',
      icon: ICONS.library,
      acessibleRoles: ['user', 'admin'],
    },
  ],
};

export default navConfig;
