import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { ReactNode, createContext, useState } from 'react';

// ----------------------------------------------------------------------

export enum ModalTypes {
  delete = 'delete',
  activate = 'activate',
  deactivate = 'deactivate',
}

export type ModalConfirmContextProps = {
  showConfirmMessage: (type: ModalTypes, functionCallback: VoidFunction) => void;
};

const initialState: ModalConfirmContextProps = {
  showConfirmMessage: () => {},
};

const ConfirmModalContext = createContext(initialState);

type OrbitProviderProps = {
  children: ReactNode;
};

function ConfirmModalProvider({ children }: OrbitProviderProps) {
  const [showModal, setShowModal] = useState<boolean>(false);

  const [modalConfig, setModalConfig] = useState<{
    message: string;
    confirmButton: string;
    confirmVariant: 'error' | 'success';
    callback: VoidFunction;
  }>();

  const showConfirmMessage = (type: ModalTypes, functionCallback: VoidFunction) => {
    switch (type) {
      case ModalTypes.activate:
        setModalConfig({
          message: 'Deseja esse conteúdo visível no App?',
          confirmButton: 'Ativar',
          confirmVariant: 'success',
          callback: functionCallback,
        });
        break;
      case ModalTypes.deactivate:
        setModalConfig({
          message: 'Deseja tornar este conteúdo indisponível no App?',
          confirmButton: 'Desativar',
          confirmVariant: 'error',
          callback: functionCallback,
        });
        break;
      case ModalTypes.delete:
        setModalConfig({
          message: 'Deseja excluir este item permanentemente?',
          confirmButton: 'Excluir',
          confirmVariant: 'error',
          callback: functionCallback,
        });
        break;
      default:
        break;
    }

    setShowModal(true);
  };

  return (
    <ConfirmModalContext.Provider
      value={{
        showConfirmMessage,
      }}
    >
      {!!modalConfig && (
        <Dialog
          open={showModal}
          maxWidth="lg"
          onClose={() => setShowModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirmar</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {modalConfig.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowModal(false)} color="inherit">
              Cancelar
            </Button>
            <Button
              onClick={() => {
                setShowModal(false);
                modalConfig.callback && modalConfig.callback();
              }}
              autoFocus
              variant="contained"
              color={modalConfig.confirmVariant}
              sx={{ color: '#fff' }}
            >
              {modalConfig.confirmButton}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {children}
    </ConfirmModalContext.Provider>
  );
}

export { ConfirmModalProvider, ConfirmModalContext };
