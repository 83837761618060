import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import brLocale from 'date-fns/locale/pt-BR';

import ThemeProvider from 'src/theme';
import ThemeColorPresets from 'src/components/ThemeColorPresets';

import { AuthProvider } from './JWTContext';
import { CollapseDrawerProvider } from './CollapseDrawerContext';
import { ConfirmModalProvider } from './ConfirmModalContext';
import { PermissionsProvider } from './PermissionsContext';
import { StepperProvider } from './StepperContext';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <PermissionsProvider>
      <HelmetProvider>
        <CollapseDrawerProvider>
          <StepperProvider>
            <ConfirmModalProvider>
              <ThemeProvider>
                <ThemeColorPresets>
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={brLocale}>
                    {children}
                  </LocalizationProvider>
                </ThemeColorPresets>
              </ThemeProvider>
            </ConfirmModalProvider>
          </StepperProvider>
        </CollapseDrawerProvider>
      </HelmetProvider>
    </PermissionsProvider>
  </AuthProvider>
);

export default AppProvider;
