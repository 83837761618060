import { Navigate } from 'react-router-dom';
import { PATH_AFTER_LOGIN } from 'src/config';
import DashboardLayout from 'src/layouts/dashboard';
import AuthGuard from 'src/guards/AuthGuard';
import { GeneralApp } from '../../components/pageComponents/General'

export const dashRoutes = {
  path: 'dashboard',
  element: (
    <AuthGuard>
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
    { path: 'app', element: <GeneralApp /> },
  ],
}