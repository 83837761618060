import { ReactNode, createContext, useState } from 'react';

// ----------------------------------------------------------------------

export type StepperContextProps = {
  activeStep: number;
  backStep: VoidFunction;
  nextStep: VoidFunction;
  goToStep: (step: number) => void;
  resetStepper: VoidFunction;
};

const initialState: StepperContextProps = {
  activeStep: 0,
  nextStep: () => {},
  backStep: () => {},
  goToStep: () => {},
  resetStepper: () => {},
};

const StepperContext = createContext(initialState);

type StepperProviderProps = {
  children: ReactNode;
};

function StepperProvider({ children }: StepperProviderProps) {
  const [step, setStep] = useState(0);

  const onBackStep = () => {
    setStep(step - 1);
  };

  const onNextStep = () => {
    setStep(step + 1);
  };

  const goToStep = (target: number) => {
    setStep(target);
  };

  const reset = () => {
    setStep(initialState.activeStep)
  }

  return (
    <StepperContext.Provider 
      value={{ 
        activeStep: step,
        nextStep: onNextStep,
        backStep: onBackStep,
        goToStep: goToStep,
        resetStepper: reset,
      }}
    >
      {children}
    </StepperContext.Provider>
  );
}

export { StepperProvider, StepperContext };
