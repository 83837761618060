// @mui
import { styled } from '@mui/material/styles';
import { Card, Stack, Container } from '@mui/material';
// components
import Page from '../../components/Page';
// sections
import { LoginForm } from '../../sections/auth/login';
//assets

import { ReactComponent as LogoIconSvg } from '../../assets/logo.svg';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100vh',
  minWidth: 520,
  flexDirection: 'column',
  justifyContent: 'center',
}));
// ----------------------------------------------------------------------

export default function Login() {
  return (
    <Page title="Login">
      <Container maxWidth="lg">
        <RootStyle>
          <ContentStyle>
            <Card sx={{ p: 4 }}>
              <Stack alignItems="center" sx={{ mb: 5 }}>
                <LogoIconSvg />
              </Stack>
              <LoginForm />
            </Card>
          </ContentStyle>
        </RootStyle>
      </Container>
    </Page>
  );
}
