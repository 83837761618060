import { Outlet } from 'react-router-dom';
import { Container, Alert, AlertTitle } from '@mui/material';
import { UserManager } from 'src/@types/user';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  accessibleRoles: string[];
};

const useCurrentRole = () => {
  const loggedUser = window.localStorage.getItem('loggedUser');
  const user = loggedUser ? JSON.parse(loggedUser) : ({} as UserManager);
  return user.is_admin ? 'admin' : 'client';
};

export default function RoleBasedGuard({ accessibleRoles }: RoleBasedGuardProp) {
  const currentRole = useCurrentRole();

  if (!accessibleRoles.includes(currentRole)) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  return <Outlet />;
}
