// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function EllipsisPostIcon({ ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.6" cx="12" cy="12" r="11.5" stroke="white"/>
        <circle cx="12" cy="12" r="3" fill="white"/>
        <path d="M19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12C5 8.13404 8.13401 5.00003 12 5.00003C15.866 5.00003 19 8.13404 19 12Z" stroke="white"/>
      </svg>
    </Box>
  );
}
