import { ReactNode, createContext, useState, useEffect } from 'react';
import { UserManager } from 'src/@types/user';

// ----------------------------------------------------------------------

export type Permissions = {
  admin: boolean;
};

export type StepperContextProps = {
  permissions: Permissions;
  setPermissions: (permissions: Permissions) => void;
  registerPermissions: (role: boolean) => void;
};

const initialState: StepperContextProps = {
  permissions: {
    admin: false,
  },
  setPermissions: () => {},
  registerPermissions: () => {},
};

const PermissionsContext = createContext(initialState);

type PermissionsProviderProps = {
  children: ReactNode;
};

function PermissionsProvider({ children }: PermissionsProviderProps) {
  const [permissions, setPermissions] = useState(initialState.permissions);

  const registerPermissions = (role: boolean) => {
    if (role) {
      setPermissions({ admin: true });
    } else {
      setPermissions({ admin: false });
    }
  };

  useEffect(() => {
    const loggedUser = window.localStorage.getItem('loggedUser');
    const user = loggedUser ? JSON.parse(loggedUser) : ({} as UserManager);

    if (user && user._id) registerPermissions(user.is_admin);
  }, []);

  return (
    <PermissionsContext.Provider
      value={{
        permissions,
        setPermissions,
        registerPermissions,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
}

export { PermissionsProvider, PermissionsContext };
