import isString from 'lodash/isString';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
// type
import { UploadProps } from './type';
//
import Image from '../Image';
import RejectionFiles from './RejectionFiles';
import BlockContent from './BlockContent';
import { UploadIllustration } from 'src/assets';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

export default function UploadSingleFile({
  error = false,
  file,
  helperText,
  type,
  sx,
  ...other
}: UploadProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  });
  
  const isVideo = type && (type === 'video/mp4' || type === 'video');

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
          ...(file && !isVideo && {
            padding: '12% 0',
          }),
        }}
      >
        <input {...getInputProps()} />

        {!file ? <BlockContent /> : <Stack sx={{ minHeight: isVideo ? 0 : 200 }} />}

        {file && (
          <>
            {
              isVideo
              ? (<video controls>
                  <source src={isString(file) ? file : file.preview} id="video"/>
                </video>
              )
              : (<>
                {isString(file) || file.type !== "application/pdf" 
                  ? (<Image
                    disableZoom
                    alt="file preview"
                    src={isString(file) ? file : file.preview}
                    sx={{
                      top: 8,
                      left: 8,
                      borderRadius: 1,
                      position: 'absolute',
                      width: 'calc(100% - 16px)',
                      height: 'calc(100% - 16px)',
                    }}
                  />)
                  : (
                    <Stack
                      spacing={2}
                      alignItems="center"
                      justifyContent="center"
                      direction={{ xs: 'column', md: 'row' }}
                      sx={{
                        top: 8,
                        left: 8,
                        borderRadius: 1,
                        position: 'absolute',
                        width: 'calc(100% - 16px)',
                        height: 'calc(100% - 16px)',
                      }}
                    >
                      <UploadIllustration sx={{ width: 170 }} />
                      <Typography gutterBottom variant="subtitle1">
                        {file.name}
                      </Typography>
                    </Stack>
                  )
                }
                  
              </>)
            }
          </>
        )}
      </DropZoneStyle>

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {helperText && helperText}
    </Box>
  );
}
