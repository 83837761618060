import DashboardLayout from 'src/layouts/dashboard';

import { CMSUserCreate, CMSUserList, ChangePassword } from '../../components/pageComponents/CMS';
import { ContactUs } from '../../components/pageComponents/Contact';
import {
  QuestionDefaultCreate,
  QuestionDefaultList,
} from '../../components/pageComponents/QuestionDefault';

import { NotificationCreate, NotificationList } from '../../components/pageComponents/Notification';

import { UserList, UserCreate } from '../../components/pageComponents/User';
import {
  QuestionUserCreate,
  QuestionUserList,
} from 'src/routes/components/pageComponents/QuestionUser';
import {
  NotebookCreate,
  // QuestionUserCreate,
  NotebookList,
  NotebookListQuestions,
  NotebookListQuestionsAnswer,
} from 'src/routes/components/pageComponents/Notebook';
import { CoverList, CoverCreate } from 'src/routes/components/pageComponents/Cover';
import { IconList, IconCreate } from 'src/routes/components/pageComponents/Icons';
import { AnswerCreate } from 'src/routes/components/pageComponents/answer';

export const mainPrivateRoutes = [
  {
    path: 'customer',
    element: <DashboardLayout accessibleRoles={['admin', 'user', 'sac']} />,
    children: [
      { path: 'list', element: <UserList /> },
      { path: ':id/edit', element: <UserCreate /> },
    ],
  },
  {
    path: 'notifications',
    element: <DashboardLayout accessibleRoles={['admin', 'user', 'sac']} />,
    children: [
      { path: '', element: <NotificationList /> },
      { path: 'new', element: <NotificationCreate /> },
    ],
  },
  {
    path: 'manager',
    element: <DashboardLayout accessibleRoles={['admin']} />,
    children: [
      { path: 'list', element: <CMSUserList /> },
      { path: 'new', element: <CMSUserCreate /> },
      { path: ':id/edit', element: <CMSUserCreate /> },
      { path: ':id/change-password', element: <ChangePassword /> },
    ],
  },
  {
    path: 'contact-us',
    element: <DashboardLayout accessibleRoles={['admin', 'sac']} />,
    children: [{ path: '', element: <ContactUs /> }],
  },
  {
    path: 'questions',
    element: <DashboardLayout accessibleRoles={['admin']} />,
    children: [
      { path: 'list', element: <QuestionDefaultList /> },
      { path: 'new', element: <QuestionDefaultCreate /> },
      { path: ':id/edit', element: <QuestionDefaultCreate /> },
    ],
  },
  {
    path: 'questions-users',
    element: <DashboardLayout accessibleRoles={['admin']} />,
    children: [
      { path: 'list', element: <QuestionUserList /> },
      { path: 'new', element: <QuestionUserCreate /> },
      { path: ':id/edit', element: <QuestionUserCreate /> },
    ],
  },
  {
    path: 'notebooks',
    element: <DashboardLayout accessibleRoles={['admin']} />,
    children: [
      { path: '', element: <NotebookList /> },
      { path: ':id/edit', element: <NotebookCreate /> },
      { path: ':id/questions/:title', element: <NotebookListQuestions /> },
      { path: ':id/:questionId/answers/:title', element: <NotebookListQuestionsAnswer /> },
    ],
  },
  {
    path: 'cover',
    element: <DashboardLayout accessibleRoles={['admin', 'user', 'sac']} />,
    children: [
      { path: '', element: <CoverList /> },
      { path: 'new', element: <CoverCreate /> },
      { path: ':id/edit', element: <CoverCreate /> },
    ],
  },
  {
    path: 'icons',
    element: <DashboardLayout accessibleRoles={['admin', 'user', 'sac']} />,
    children: [
      { path: '', element: <IconList /> },
      { path: 'new', element: <IconCreate /> },
      { path: ':id/edit', element: <IconCreate /> },
      { path: 'new/:id/category', element: <IconCreate /> },
      { path: 'edit/:id/category/:idIcon', element: <IconCreate /> },
    ],
  },
  {
    path: 'answer',
    element: <DashboardLayout accessibleRoles={['admin', 'user', 'sac']} />,
    children: [
      { path: 'new', element: <AnswerCreate /> },
      { path: ':id/:questionId/:idBack/edit', element: <AnswerCreate /> },
    ],
  },
];
