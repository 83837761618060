import GuestGuard from 'src/guards/GuestGuard';
import { Login, FBDelete, Register, ResetPassword, RecoveryPassword, VerifyCode } from '../../components/pageComponents/Authentication'

export const routes = {
  path: 'auth',
  children: [
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: 'register',
      element: (
        <GuestGuard>
          <Register />
        </GuestGuard>
      ),
    },
    { path: 'login-unprotected', element: <Login /> },
    { path: 'register-unprotected', element: <Register /> },
    { path: 'reset-password', element: <ResetPassword /> },
    { path: 'createNewPassword/:token', element: <RecoveryPassword /> },
    { path: 'verify', element: <VerifyCode /> },
    { path: 'delete-fb-data', element: <FBDelete /> },
  ],
}