// @mui
import { styled } from '@mui/material/styles';
// components
import Page from '../components/Page';
// sections

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  margin: 30,
  alignContent: 'center',
}));

// ----------------------------------------------------------------------

export default function PageTerms() {
  return (
    <Page title="The starting point for your next project">
      <RootStyle>
        <ContentStyle>
          <div className="container">
            <h2>Política de Privacidade</h2>
            <br />
            <a href="https://www.caderninho.com.br/" target="_blank" rel="noreferrer">
              MeNote
            </a>{' '}
            criou o aplicativo MeNote como um aplicativo comercial. Este SERVIÇO é fornecido e deve
            ser usado como está.
            <br />
            <br />
            Esta página é usada para informar os visitantes sobre nossas políticas de coleta, uso e
            divulgação de Informações pessoais, se alguém decidir usar nosso Serviço.
            <br />
            <br />
            Se você optar por usar nosso Serviço, concorda em coletar e usar informações em relação
            a esta política. As informações pessoais que coletamos são usadas para fornecer e
            melhorar o serviço. Não usaremos ou compartilharemos suas informações com ninguém,
            exceto conforme descrito nesta Política de Privacidade.
            <br />
            <br />
            Os termos usados nesta Política de Privacidade têm os mesmos significados que em nossos
            Termos e Condições, que podem ser acessados pelo MeNote, a menos que definido de outra
            forma nesta Política de Privacidade.
            <br />
            <br />
            <br />
            <br />
            <h4>Coleta e uso de informações</h4>
            Para uma experiência melhor, ao usar nosso Serviço, podemos solicitar que você nos
            forneça certas informações de identificação pessoal, incluindo, mas não se limitando a
            localização geográfica e mensagens de texto. As informações que solicitamos serão
            retidas por nós e usadas conforme descrito nesta política de privacidade.
            <br />
            <br />
            O aplicativo usa serviços de terceiros que podem coletar informações usadas para
            identificá-lo.
            <br />
            <br />
            Link para a política de privacidade de provedores de serviços de terceiros usados pelo
            aplicativo na Play Store:{' '}
            <a href="https://www.google.com/policies/privacy/">
              <b>Google Play Services</b>
            </a>
            <br />
            Link para a política de privacidade de provedores de serviços de terceiros usados pelo
            aplicativo na App Store:{' '}
            <a href="https://www.apple.com/legal/internet-services/itunes/br/terms.html">
              <b>Apple Store Services</b>
            </a>
            <br />
            <br />
            <br />
            <br />
            <h4>Dados de log</h4>
            Queremos informar que, sempre que você usa nosso Serviço, em caso de erro no aplicativo,
            coletamos dados e informações (através de produtos de terceiros) no seu telefone chamado
            Log Data. Esses dados de registro podem incluir informações como o endereço IP do
            dispositivo, nome do dispositivo, versão do sistema operacional, a configuração do
            aplicativo ao utilizar nosso serviço, a hora e a data de uso do serviço e outras
            estatísticas.
            <br />
            <br />
            <br />
            <h4>Cookies</h4>
            Cookies são arquivos com uma pequena quantidade de dados que são comumente usados como
            identificadores exclusivos anônimos. Eles são enviados para o navegador a partir dos
            sites visitados e armazenados na memória interna do dispositivo.
            <br />
            <br />
            Este serviço não usa esses "cookies" explicitamente. No entanto, o aplicativo pode usar
            código e bibliotecas de terceiros que usam "cookies" para coletar informações e melhorar
            seus serviços. Você tem a opção de aceitar ou recusar esses cookies e saber quando um
            cookie está sendo enviado para o seu dispositivo. Se você optar por recusar nossos
            cookies, poderá não conseguir usar algumas partes deste serviço.
            <br />
            <br />
            <br />
            <h4>Provedores de serviço</h4>
            Podemos empregar empresas e indivíduos de terceiros devido aos seguintes motivos:
            <br />
            <br />
            <ul>
              <li>Para facilitar nosso serviço;</li>
              <li>Fornecer o serviço em nosso nome;</li>
              <li>Executar serviços relacionados ao serviço; ou</li>
              <li>Para nos ajudar a analisar como nosso Serviço é usado.</li>
            </ul>
            <br />
            Queremos informar aos usuários deste Serviço que esses terceiros têm acesso às suas
            Informações Pessoais. O motivo é executar as tarefas atribuídas a eles em nosso nome. No
            entanto, eles são obrigados a não divulgar ou usar as informações para qualquer outra
            finalidade.
            <br />
            <br />
            <br />
            <h4>Segurança</h4>
            Valorizamos sua confiança em nos fornecer suas informações pessoais, portanto, estamos
            nos esforçando para usar meios comercialmente aceitáveis de protegê-las. Mas lembre-se
            de que nenhum método de transmissão pela Internet ou método de armazenamento eletrônico
            é 100% seguro e confiável, e não podemos garantir sua segurança absoluta.
            <br />
            <br />
            <br />
            <h4>Privacidade das crianças</h4>
            Esses serviços não tratam de menores de 13 anos. Não coletamos intencionalmente
            informações de identificação pessoal de crianças menores de 13 anos. No caso de
            descobrirmos que uma criança menor de 13 anos nos forneceu informações pessoais, as
            excluiremos imediatamente de nossos servidores. Se você é pai ou mãe ou responsável e
            sabe que seu filho nos forneceu informações pessoais, entre em contato conosco para que
            possamos realizar as ações necessárias.
            <br />
            <br />
            <br />
            <h4>Alterações a esta Política de Privacidade</h4>
            Podemos atualizar nossa Política de Privacidade periodicamente. Portanto, é recomendável
            revisar esta página periodicamente para verificar se há alterações. Notificaremos você
            sobre quaisquer alterações, publicando a nova Política de Privacidade nesta página.
            Essas alterações entram em vigor imediatamente após serem publicadas nesta página.
            <br />
            <br />
            <br />
            <h4>Contato</h4>
            Se você tiver alguma dúvida ou sugestão sobre nossa Política de Privacidade, não hesite
            em nos contactar em{' '}
            <a href="mailto:contato@menote.digital">
              <b>contato@menote.digital</b>
            </a>
            .<br />
            <br />
          </div>
        </ContentStyle>
      </RootStyle>
    </Page>
  );
}
